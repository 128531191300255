<template>
  <div>
    <div class="chats">
      <div
        v-if="!chatData || isEmpty(chatData)"
        class="d-flex-center"
      >
        <div
          class="text-light px-2 py-50 badge-pill"
          style="background-color: #80808080;"
        >
          Không có lịch sử
        </div>
      </div>
      <div
        v-for="(msgGrp, index) in chatData"
        :key="String(index)"
        class="chat mb-50"
        :class="{ 'chat-left': msgGrp && !msgGrp.token }"
      >
        <div class="chat-avatar">
          <b-avatar
            size="36"
            class="avatar-border-2 box-shadow-1"
            :variant="!msgGrp.token ? `light` : 'light-info'"
            :src="!msgGrp.token ? profileUserAvatar : ''"
          />
        </div>
        <div
          class="chat-body d-flex align-items-center gap-2"
          :class="`${msgGrp && !msgGrp.token ? '' : 'flex-row-reverse'}`"
          style="margin-right: 0px; margin-top: 0px;"
        >
          <div class="chat-content mb-0">
            <div
              v-for="(msgData, msgDataIndex) in msgGrp.messages"
              :key="msgDataIndex"
            >
              <span style="white-space: pre-wrap; word-break: keep-all">{{ msgData }}</span>
            </div>
          </div>
          <BButton
            variant="flat-info"
            class="p-50 hover-button"
            style="height: fit-content;"
            @click="copyText(msgGrp.messages)"
          >
            {{ $t('reservation.copy') }}
          </BButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BButton } from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'

export default {
  components: {
    BAvatar,
    BButton,
  },
  props: {
    chatData: {
      type: [Object, Array, undefined],
      default: () => { },
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  setup() {
    function copyText(text) {
      navigator.clipboard.writeText(text.join('\n'))
    }
    return {
      isEmpty,
      copyText,
    }
  },
}
</script>

<style lang="scss" scoped>
.chat {
  &:hover {
    .hover-button {
      display: block;
    }
  }

  .hover-button {
    display: none;
  }
}
</style>
