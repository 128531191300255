var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "chats"
  }, [!_vm.chatData || _vm.isEmpty(_vm.chatData) ? _c('div', {
    staticClass: "d-flex-center"
  }, [_c('div', {
    staticClass: "text-light px-2 py-50 badge-pill",
    staticStyle: {
      "background-color": "#80808080"
    }
  }, [_vm._v(" Không có lịch sử ")])]) : _vm._e(), _vm._l(_vm.chatData, function (msgGrp, index) {
    return _c('div', {
      key: String(index),
      staticClass: "chat mb-50",
      class: {
        'chat-left': msgGrp && !msgGrp.token
      }
    }, [_c('div', {
      staticClass: "chat-avatar"
    }, [_c('b-avatar', {
      staticClass: "avatar-border-2 box-shadow-1",
      attrs: {
        "size": "36",
        "variant": !msgGrp.token ? "light" : 'light-info',
        "src": !msgGrp.token ? _vm.profileUserAvatar : ''
      }
    })], 1), _c('div', {
      staticClass: "chat-body d-flex align-items-center gap-2",
      class: "".concat(msgGrp && !msgGrp.token ? '' : 'flex-row-reverse'),
      staticStyle: {
        "margin-right": "0px",
        "margin-top": "0px"
      }
    }, [_c('div', {
      staticClass: "chat-content mb-0"
    }, _vm._l(msgGrp.messages, function (msgData, msgDataIndex) {
      return _c('div', {
        key: msgDataIndex
      }, [_c('span', {
        staticStyle: {
          "white-space": "pre-wrap",
          "word-break": "keep-all"
        }
      }, [_vm._v(_vm._s(msgData))])]);
    }), 0), _c('BButton', {
      staticClass: "p-50 hover-button",
      staticStyle: {
        "height": "fit-content"
      },
      attrs: {
        "variant": "flat-info"
      },
      on: {
        "click": function click($event) {
          return _vm.copyText(msgGrp.messages);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('reservation.copy')) + " ")])], 1)]);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }